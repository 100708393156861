import { createApp } from "vue";
import Login from "./Login.vue";

import ElementPlus from "element-plus";
import es from "element-plus/es/locale/lang/es";
import "element-plus/theme-chalk/index.css";
import "@/assets/css/login.css";
import "@fontsource/roboto/400.css";

var app = createApp(Login).use(ElementPlus, {
    locale: es,
});

app.mount("#app");
